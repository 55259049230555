import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpErrorResponse } from '@angular/common/http';
import { Employee } from './employee';
import { environment } from './../../environments/environment';
import { map,catchError } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class EmployeeService {
 
    APIEndpoint = environment.APIEndpoint;
    private baseUri=this.APIEndpoint;  
    private headers=new HttpHeaders().set('Content-Type','application/json');

    constructor(private _http:HttpClient) { }


    private extractData(res: Response) {
      let body = res;
      return body || { };
    }

    private handleError(error: HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      }
      // return an observable with a user-facing error message
      return throwError('Something bad happened; please try again later.');
    };

    //Employee report data table

    getEmployeeReport(date): Observable<any> {
      
          return this._http.post(this.baseUri+'/getempdetails',{date:date},{headers:this.headers}).pipe(
          map(this.extractData),
          catchError(this.handleError));   
      
      }

      //employee active window report - inside modal
      
      getEmpActiveWindowReport(empcode,date): Observable<any> {
      
      // console.log(empcode,'inside getEmpActiveWindowReport in service page'+ empcode)
      // console.log(empcode,'inside getEmpActiveWindowReport in service page'+ date)

        return this._http.post(this.baseUri+'/getuseractivewindowlog',{empcode:empcode,date:date},{headers:this.headers}).pipe(
        map(this.extractData),
        catchError(this.handleError));   

    }

    //employee screen shot report - inside modal
      
    getEmpScreenShotReport(empcode,date): Observable<any> {
      
    // console.log(empcode,'inside getEmpScreenShotReport in service page'+ empcode)
    // console.log(empcode,'inside getEmpScreenShotReport in service page'+ date)

      return this._http.post(this.baseUri+'/getdisplayscreenshot',{empcode:empcode,date:date},{headers:this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError));   

    }
   
    //Screenshot list
    getEmployeelist(){
          return this._http.get(this.baseUri+'/gettotalcheckin',{headers:this.headers});
    }

    getIntervalList(){
          return this._http.get(this.baseUri+'/getss_interval',{headers:this.headers});
    }

    //Insert screenshot
    insertScreenshot(employee:Employee)
    {
          //console.log('inside screenshot serice.ts file',employee);
          return this._http.post(this.baseUri+'/postconfigscreenshot',employee,{headers:this.headers});
    }

    insertDataConfiguration(employee:Employee)
    {
          //console.log('inside service',employee);
          return this._http.post(this.baseUri+'/postempdataconfig',employee,{headers:this.headers});
    }

    //Dashobard Profile card
    getProfileCard(profile:Employee)
    {
          return this._http.post(this.baseUri+'/getuser',profile,{headers:this.headers});
    }

    getStateList()
    {
      return this._http.get(this.baseUri+'/getstate',{headers:this.headers});
    }

    getCountryList()
    {
      return this._http.get(this.baseUri+'/getcountry',{headers:this.headers});
    }

    updateAddress(address:Employee)
    {
      //console.log('Update Address Angular service ' +address) ;
      
      return this._http.post(this.baseUri+'/updateempaddress',address,{headers:this.headers});
      
    }

    getAbseentesList(date){
      return this._http.post(this.baseUri+'/getempabsentdetails',{date},{headers:this.headers});
    }

    //Monthly report data table
    getMonthlyReport(employee:Employee) {

     // console.log('getMonthlyReport service page',employee);
      return this._http.post(this.baseUri+'/getattendance_monthly',employee,{headers:this.headers});  
    }

}
