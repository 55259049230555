import { Component, OnInit, Input } from '@angular/core';
import { menus } from './menu-element';

@Component({
  selector: 'cdk-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {

    @Input() iconOnly:boolean = false;
    public menus = menus;

    sessionvalue:any;
    code:any;
    roleId:any;
    
    constructor() { }

    ngOnInit() {
      this.sessionvalue = JSON.parse(localStorage.getItem("LoggedInUser"));
      this.code = this.sessionvalue['msg'];
  
      //this.roleId = "user";
      //this.roleId = "admin";
      this.roleId = "superadmin";
    }

}
