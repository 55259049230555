import { Component, OnInit, Input, HostListener, ElementRef } from '@angular/core';

import { Employee } from'../../services/employee';
import { EmployeeService } from '../../services/employee.service';

@Component({
  selector: 'cdk-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
	
	employee: Employee = new Employee();

	sessionvalue:any;
    code:any;
	userInfo:any;
	fullname:string;

	isOpen: boolean = false;

  	//currentUser = null;
  	Hari;
  	

  	@Input() currentUser = null;
  	@HostListener('document:click', ['$event', '$event.target'])
  	onClick(event: MouseEvent, targetElement: HTMLElement) {
    	if (!targetElement) {
     		return;
    	}

    	const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    	if (!clickedInside) {
      		this.isOpen = false;
    	}
  	}
  	
    
	constructor(private elementRef: ElementRef,private employeeService:EmployeeService) { }


  	ngOnInit() {
		this.sessionvalue = JSON.parse(localStorage.getItem("LoggedInUser"));
		this.code = this.sessionvalue['msg'];
	   // console.log('Get values in session',this.sessionvalue['msg'][0]); 
		 console.log(this.sessionvalue,'Get Session Value',this.code);
	
		// this.getUserInfo();
  	}

	//User name list    
	getUserInfo()
	{      
	this.sessionvalue = JSON.parse(localStorage.getItem("LoggedInUser"));
	this.employee.code = this.sessionvalue['msg'];
	console.log(this.code,'Profile list');
	this.employeeService.getProfileCard(this.employee).subscribe(
	data=>{
	console.log(this.code);
	this.userInfo=data['msg'];
	this.userInfo.first_name=data['msg'][0]['first_name'];
	this.userInfo.middel_name=data['msg'][0]['middel_name'];
	this.userInfo.last_name=data['msg'][0]['last_name'];

	// this.fullname=data['msg'][0]['first_name']+" "+data['msg'][0]['middel_name']+" "+data['msg'][0]['last_name'];
	this.fullname = this.userInfo.first_name+" "+this.userInfo.middel_name+" "+this.userInfo.last_name;

    console.log(this.fullname);
	// this.count=this.members.length;

//	console.log(this.userInfo.first_name,'First Name');
//	console.log(this.userInfo.middel_name,'Middle Name');
//	console.log(this.userInfo.last_name,'Last Name');
	},
	error=>{
	console.log(error);
	}
	)
	}


}
