import { Component, OnInit,Output,EventEmitter} from '@angular/core';
import { Router } from '@angular/router';

import { ViewEncapsulation,ViewChild,Inject } from '@angular/core';
import { FormGroup, FormArray, FormBuilder,Validators,FormControl } from '@angular/forms';
import { MatDialogRef,MatDialog, MAT_DIALOG_DATA, } from '@angular/material';
import { AppserviceService } from '../services/appservice.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';


@Component({
  selector: 'app-sessiontimeout',
  templateUrl: './sessiontimeout.component.html',
  styleUrls: ['./sessiontimeout.component.scss']
})
export class SessiontimeoutComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private dialogRef: MatDialogRef<SessiontimeoutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private idle: Idle,
    private appService:AppserviceService) { }

  stmt: any;
  timedOut:any;

  ngOnInit() {

    // Bind datas from employee.ts page
    console.log("Inside session comp ts page===>" +this.data.idleState);
    
      this.stmt = this.data.idleState;
  }

  // @Output() onReset: EventEmitter<any> = new EventEmitter<any>();

  //  stayClick() {
  //   this.dialogRef.close();
  //   this.reset();
  // }

 

  logoutClick() {
    this.dialogRef.close();
    this.appService.setUserLoggedIn(false);
    this.router.navigate(['/auth/logout']);
  }

  // reset() {
  //   this.idle.watch();
  //   //xthis.idleState = 'Started.';
  //   this.timedOut = false;
  //   this.onReset.emit(this.timedOut);
  // }

}
