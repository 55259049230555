import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LazyLoadModule } from './lazy-load/lazy-load.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';

import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'angular2-moment'; // optional, provides moment-style pipes for date formatting

import { MatProgressSpinnerModule } from '@angular/material';
import { MatIconModule,MatTabsModule,
        MatButtonModule,MatFormFieldModule,MatCheckboxModule,
        MatInputModule,MatRippleModule, MatSelectModule,MatPaginatorModule,MatSortModule,MatDialogModule,
        MatDatepickerModule, MatNativeDateModule,MatTooltipModule,MatMenuModule, MatGridListModule, MatToolbarModule, 
        MatSnackBarModule, MatTableModule,MatCardModule,
        MatSidenavModule} from '@angular/material';

import { SessiontimeoutComponent } from './sessiontimeout/sessiontimeout.component';
import { SessiontimeoutModule } from './sessiontimeout/sessiontimeout.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents:[SessiontimeoutComponent],
  imports: [
    BrowserModule,SessiontimeoutModule,
    LazyLoadModule,
    CoreModule,
    BrowserAnimationsModule,HttpClientModule,
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    MatButtonModule, MatCheckboxModule, MatSelectModule,MatPaginatorModule,MatSortModule,MatDialogModule,
    MatFormFieldModule, MatInputModule,MatTabsModule,MatCardModule,MatRippleModule,
    MatDatepickerModule,MatNativeDateModule,MatProgressSpinnerModule,MatIconModule,MatSidenavModule,
    MatTooltipModule,MatMenuModule, MatGridListModule, MatToolbarModule,MatSnackBarModule, MatTableModule,
  ],
  providers: [DatePipe,{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent],
  
})
export class AppModule { }
