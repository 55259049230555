import { Component, ViewChild, TemplateRef, ElementRef} from '@angular/core';
import { Router } from '@angular/router';


import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { MatTableDataSource,MatPaginator,MatSort,MatDialog,MatDialogRef } from '@angular/material';
import { SessiontimeoutComponent } from './sessiontimeout/sessiontimeout.component';

import { AppserviceService } from './services/appservice.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent{

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';
  
  dialogRef: any;

  constructor(private dialog: MatDialog,private idle: Idle, private keepalive: Keepalive,
               private router: Router,
               private appService:AppserviceService) {

                idle.setIdle(600);
                // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
                idle.setTimeout(120);
                // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
                idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
            
                idle.onIdleEnd.subscribe(() => { 
                  this.idleState = 'No longer idle.'
                  console.log(this.idleState);
                  this.dialogRef = this.dialog.closeAll();
                });
                
                idle.onTimeout.subscribe(() => {
                  this.dialogRef.close();
                  this.idleState = 'Timed out!';
                  this.timedOut = true;
                  console.log(this.idleState);
                  this.router.navigate(['/auth/logout']);
                });
                
                idle.onIdleStart.subscribe(() => {
                    this.idleState = 'You\'ve gone idle!'
                    console.log(this.idleState);
                    //----------Active window - Material Dialog Box ------------//
        
                    this.dialogRef = this.dialog.open(SessiontimeoutComponent,{
                      width: '500px',
                      height: '200px',
                      data: {
                        idleState : this.idleState
                      }
                    });

                });
                
                
                idle.onTimeoutWarning.subscribe((countdown) => {
                  this.idleState = 'You will time out in ' + countdown + ' seconds!'
                  console.log(this.idleState);
                });
            
                // sets the ping interval to 15 seconds
                keepalive.interval(2);
            
                keepalive.onPing.subscribe(() => this.lastPing = new Date());
            
                this.appService.getUserLoggedIn().subscribe(userLoggedIn => {
                  if (userLoggedIn) {
                    idle.watch()
                    this.timedOut = false;
                  } else {
                    idle.stop();
                  }
                })
            
                // this.reset();
              }
            
              reset() {
                this.idle.watch();
                //xthis.idleState = 'Started.';
                this.timedOut = false;
              }
            
              
              // stay() {
              //   this.childModal.hide();
              //   this.reset();
              // }
            
              // logout() {
              //   this.childModal.hide();
              //   this.appService.setUserLoggedIn(false);
              //   this.router.navigate(['/auth/logout']);
              // }
            
  } 