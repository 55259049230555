import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SessiontimeoutRoutingModule } from './sessiontimeout-routing.module';
import { SessiontimeoutComponent } from './sessiontimeout.component';
import { MatDialogModule,MatButtonModule,MatInputModule,
         MatGridListModule,MatCardModule} from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    SessiontimeoutRoutingModule,
    FormsModule,ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    MatDialogModule,MatButtonModule,MatInputModule,MatGridListModule,MatCardModule
  ],
  declarations: [SessiontimeoutComponent]
})
export class SessiontimeoutModule { }
