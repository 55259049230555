import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppserviceService {

  private userLoggedIn = new Subject<boolean>();

  constructor() {
    this.userLoggedIn.next(false);
  }

  setUserLoggedIn(userLoggedIn: boolean) {

   // console.log("inside setUserLoggedIn app service===>"+userLoggedIn);
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {

  //  console.log("inside getuserloggedin app service===>"+this.userLoggedIn.asObservable());
    return this.userLoggedIn.asObservable();
  }

}
